.InquiryForm {
    margin: 20px auto;
    width: 700px;
    /* text-align: center; */
    box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee;
    padding: 30px;
    box-sizing: border-box;
    background-color: white;
}

.InputEle {
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    padding: 6px 10px;
    font: inherit;
    width: 100%;
    box-sizing: border-box;
}

.InputEle:focus {
    outline: none;
    background-color: #ccc;
}

.logo {
    color: #fff;
    cursor: pointer;
}

.logoImg {
    width: 200px;
    /* Set a fixed width for the logo */
    height: auto;
    position: relative;
}

.Lable {
    font-weight: bold;
    display: block;
    font-size: calc(1*var(--rem-base));
    line-height: calc(1.2*var(--rem-base));
    font-weight: 700;
    letter-spacing: calc(.06*var(--rem-base));
    text-transform: uppercase;
    color: #000c37;
    /* display: inline-block; */
    margin-bottom: .5rem;
}

.buttoncontainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ButtonForComponent {
    background-color: black;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 10px;
    font-weight: bold;
    text-align: center;
    margin-left: 270px;
    box-shadow: 0px 5px 15px 0px rgba(28, 0, 181, 0.3);
    margin-top: 50px;


}

.ButtonList {
    background-color: white;
    border: none;
    color: black;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 10px;
    font-weight: bold;
    text-align: center;
    box-shadow: 0px 5px 5px 0px rgba(28, 0, 181, 0.3);


}


/* Base styles for screens with a width greater than the largest breakpoint */
.InquiryForm {
    margin: 20px auto;
    width: 700px;
    box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee;
    padding: 30px;
    box-sizing: border-box;
    background-color: white;
}

/* Media query for screens up to 1024px */
@media (max-width: 1024px) {
    .InquiryForm {
        width: 90%; /* Adjust width for screens up to 1024px */
    }

    .ButtonForComponent {
        margin-left: 0; /* Reset margin for the button */
    }
}

/* Media query for screens up to 768px */
@media (max-width: 768px) {
    .InquiryForm {
        width: 95%; /* Adjust width for screens up to 768px */
    }

    .InputEle {
        padding: 6px; /* Adjust padding for input elements */
    }

    .ButtonForComponent {
        margin-left: 0; /* Reset margin for the button */
    }

    .ButtonList {
        margin-left: 0; /* Reset margin for the button */
    }
}

/* Media query for screens up to 480px */
@media (max-width: 480px) {
    .InquiryForm {
        width: 100%; /* Adjust width for screens up to 480px */
        padding: 20px; /* Adjust padding for smaller screens */
    }

    .Lable {
        font-size: 16px; /* Adjust font size for labels */
    }

    .ButtonForComponent,
    .ButtonList {
        margin-left: 0; /* Reset margin for the buttons */
    }
}
