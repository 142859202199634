.Input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.Lable {
    font-weight: bold;
    display: block;
    font-size: 12px;
    line-height: calc(1.2*var(--rem-base));
    font-weight: 700;
    letter-spacing: calc(.06*var(--rem-base));
    text-transform: uppercase;
    color: #000c37;
    /* display: inline-block; */
    margin-bottom: 8px;
}

.InputEle {
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    padding: 6px 10px;
    font: inherit;
    display: block;
    width: 100%;
    box-sizing: border-box;
}

.InputEle:focus {
    outline: none;
    background-color: #ccc;
}

/* Media query for screens up to 1024px */
@media (max-width: 1024px) {
    .Input {
        /* Adjust styles for screens up to 1024px */
    }
}

/* Media query for screens up to 768px */
@media (max-width: 768px) {
    .Input {
        /* Adjust styles for screens up to 768px */
    }

    .Lable {
        font-size: 14px; /* Adjust font size for labels */
    }

    .InputEle {
        padding: 8px; /* Adjust padding for input elements */
    }
}

/* Media query for screens up to 480px */
@media (max-width: 480px) {
    .Input {
        /* Adjust styles for screens up to 480px */
    }

    .Lable {
        font-size: 12px; /* Adjust font size for labels */
        margin-bottom: 6px; /* Adjust margin for labels */
    }

    .InputEle {
        padding: 6px; /* Adjust padding for input elements */
    }
}