/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

/* Reset default styles */
* {
  margin: 0;
  padding: 0;
  transition: 0.5s;
  font-family: 'Poppins', sans-serif;
}

.main-container {

}

/* Set the background and container styles */
.container-fluid {
  width: 100%;
  height: 100vh; /* Change height to min-height for content to expand */
  background-color: #fff;
  overflow: auto;

}

.container {
  width: 90%; /* Adjust width for responsiveness */
  max-width: 1200px; /* Add max-width to limit container size */
  margin: auto;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Logo styles */
.logo {
  color: #fff;
  cursor: pointer;
}

.logo-img {
  width: 200px; /* Set a fixed width for the logo */
  height: auto;
  position: relative;
}

/* Navigation styles */
nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav ul li {
  margin-right: 30px;
  cursor: pointer;
  color: black;
  font-weight: 400;
  font-size: 15px;
}

/* Highlight the "Partnership" item on hover */
nav ul li:hover {
  color: #058283;
}

/* Button styles */
.btn {
  width: 160px;
  height: 45px;
  border-radius: 8px;
  border: none;
  background: #058283;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
}

.btn1 {
  width: 170px;
  height: 45px;
  border-radius: 8px;
  border: none;
  background: #058283;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
}

/* Button hover effect */
.btn:hover {
  background: black;
  color: #058283;
}

/* Button hover effect */
.btn1:hover {
  background: black;
  color: #058283;
}
/* Content styles */
.content {
  width: 90%; /* Adjust width for responsiveness */
  max-width: 1200px; /* Add max-width to limit content size */
  margin: auto;
  padding: 20px; /* Add padding for spacing on smaller screens */
}

.content h1 {
  font-size: 48px; /* Adjust font size for smaller screens */
  margin-top: 20px; /* Adjust spacing for smaller screens */
  line-height: 48px; /* Adjust line height for smaller screens */
}

.content p {
  font-size: 16px;
  margin-top: 20px; /* Adjust spacing for smaller screens */
  line-height: 24px; /* Adjust line height for smaller screens */
  letter-spacing: 1px;
}

.content .btn {
  margin-top: 20px; /* Adjust spacing for smaller screens */
  width: 140px; /* Adjust button width for smaller screens */
  height: 45px;
  font-size: 14px;
  letter-spacing: 1px;
}

/* Media Queries for Responsive Design */
@media screen and (max-width: 768px) {
  .container {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    align-items: center; /* Align items to the center of the container */
  }

  nav ul li {
    margin-right: 15px; /* Adjust spacing for smaller screens */
  }

  .logo-img {
    width: 150px; /* Adjust logo size for smaller screens */
  }

  .content h1 {
    font-size: 36px; /* Adjust font size for smaller screens */
    line-height: 40px; /* Adjust line height for smaller screens */
  }

  .content p {
    font-size: 14px; /* Adjust font size for smaller screens */
    line-height: 20px; /* Adjust line height for smaller screens */
  }

  .content .btn {
    width: 120px; /* Adjust button width for smaller screens */
  }
}
