/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

* {
    margin: 0;
    padding: 0;
    transition: 0.5s;
    font-family: 'Poppins', sans-serif;
}


.footer {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.883);
    color: white;
    height: auto;
    width: 70%;
    margin: 0 auto;
    /* Center the footer horizontally */
    padding: 20px;
}


.footer>div {
    flex: 1;
    /* Each div takes equal space */
}

/* The form div takes 60% of the space */
.footer>div:last-child {
    flex: 2;
}

.footerSection {
    margin: 0 30px;
    /* Add margin for spacing between sections */
}

.socialmedia {
    list-style: none;
    margin-top: 30px;

}

.socialmedia li {
    cursor: pointer;
    color: white;
    font-weight: 400;
    font-size: 15px;
}


/* Highlight the "Partnership" item on hover */
.socialmedia li:hover {
    color: #058283;
}

.serviceList {
    list-style: none;
    /* Use a different list-style for services */
    /* Add left padding for the list */
    margin-top: 0;
    /* Remove default top margin */
    color: white;
    font-weight: 400;
    font-size: 15px;
    margin-top: 30px;
}



.form {
    display: flex;
    flex-direction: column;
    /* Other form styles go here */
}

.form label {
    color: #ccc;
}

.formHeader {
    text-align: center;
    /* Center the h4 element */
    margin-bottom: 5px;
    /* Add some space below the h4 element */
}

h4 {
    font-size: 20px;
    /* Adjust the font size as needed */
    color: rgba(255, 255, 255, 0.577);
    /* Choose a suitable text color */
    font-weight: bold;
    /* Optionally make it bold */
}


.formGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    /* Space between form elements */
}

label {
    color: white;
    font-weight: bold;
    /* Optionally make labels bold */
    flex: 1;
    /* Distribute label width evenly with input/textarea */
}

input[type="email"],
textarea {
    height: auto;
    flex: 2;
    /* Distribute input/textarea width evenly with label */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    color: #333;
    margin-right: 150px;
}

.btnContainer {
    display: flex;
    justify-content: center;
}

/* Button styles */
.btn {
    width: 100px;
    height: 35px;
    border-radius: 8px;
    border: none;
    background: #058283;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
}



/* Button hover effect */
.btn:hover {
    background: black;
    color: #058283;
}

@media (max-width: 1720px) {
   
}
@media (max-width: 1520px) {
    .footer {
        
        width: 80%;
        height: auto;
    }

    .footer>div:last-child {
        flex: 1;
    }

    /* Social media list styles */
    .socialmedia {
        margin-top: 10px;

    }

    .socialmedia li {
        cursor: pointer;
        color: white;
        font-weight: 400;
        font-size: 15px;
    }

    /* Service list styles */
    .serviceList {
        margin-top: 10px;
        font-size: 15px;
        margin-bottom: 10px;


    }

    h4 {
        margin-bottom: 10px;
        font-size: 18px;

    }


  

    /* Input and textarea styles */
    input[type="email"],
    textarea {
        margin-right: 0;
    }

    /* Button container styles */
    .btnContainer {
        margin-top: 10px;
    }

    /* Button styles */
    .btn {
        width: 100px;
        height: 29px;
    }
}

@media (max-width: 1133px) {
    .footer {
        
        width: 80%;
        height: auto;
    }

    .footer>div:last-child {
        flex: 1;
    }

    /* Social media list styles */
    .socialmedia {
        margin-top: 10px;

    }

    .socialmedia li {
        cursor: pointer;
        color: white;
        font-weight: 400;
        font-size: 14px;
    }

    /* Service list styles */
    .serviceList {
        margin-top: 10px;
        font-size: 14px;
        margin-bottom: 10px;


    }

    h4 {
        margin-bottom: 10px;
        font-size: 14px;

    }


    /* Form header styles */
    .formHeader {
        font-size: 16px;
    }

    /* Form group styles */
    .formGroup {
       
    }

    label {
        font-size: small;
    }

    /* Input and textarea styles */
    input[type="email"],
    textarea {
        margin-right: 0;
    }

    /* Button container styles */
    .btnContainer {
        margin-top: 10px;
    }

    /* Button styles */
    .btn {
        width: 80px;
        height: 25px;
    }

    /* Apply responsive styles for screens with a maximum width of 768px */
@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 90%;
        height: auto;
    }

    .footer>div:last-child {
        flex: 1;
    }

    /* Social media list styles */
    .socialmedia {
        margin-top: 10px;
        margin-bottom: 10px;

    }

    .socialmedia li {
        cursor: pointer;
        color: white;
        font-weight: 400;
        font-size: 14px;
    }

    /* Service list styles */
    .serviceList {
        margin-top: 10px;
        font-size: 14px;
        margin-bottom: 10px;


    }

    h4 {
        margin-bottom: 10px;
    }


    /* Form header styles */
    .formHeader {
        font-size: 16px;
    }

    /* Form group styles */
    .formGroup {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }

    label {
        font-size: large;
    }

    /* Input and textarea styles */
    input[type="email"],
    textarea {
        margin-right: 0;
        width: 300px;
    }

    /* Button container styles */
    .btnContainer {
        margin-top: 10px;
    }

    /* Button styles */
    .btn {
        width: 100%;
    }

    /* Apply responsive styles for screens with a maximum width of 480px */
    @media (max-width: 480px) {
        .footer {
            width: 100%;
            /* Adjust the width for smaller screens */
            padding: 10px;
        }

        /* Footer section styles */
        .footerSection {
            margin: 0;
        }

        h4 {
            margin-top: 20px;
        }

        /* Social media list styles */
        .socialmedia {
            margin-top: 10px;
        }

        .socialmedia li {
            cursor: pointer;
            color: white;
            font-weight: 400;
            font-size: 13px;
        }

        /* Service list styles */
        .serviceList {
            margin-top: 10px;
            font-size: 13px;

        }



        /* Form header styles */
        .formHeader {
            font-size: 16px;
        }

        /* Form group styles */
        .formGroup {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 20px;
        }

        label {
            font-size: small;
        }

        /* Input and textarea styles */
        input[type="email"],
        textarea {
            margin-right: 0;
            width: 200px;
        }

        /* Button container styles */
        .btnContainer {
            margin-top: 10px;
        }

        /* Button styles */
        .btn {
            width: 100%;
        }
    }

}
}


