  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');


* {
  margin: 0;
  padding: 0;
}

/* register.css */
.register-page-element {
  /* Reset the overflow property for elements with this class */
  overflow: visible;
}


body {
  background-color: #f2efef;
  font-size: 14px;
}

.container-register {
  width: 80%;
  margin: 50px auto;
  overflow: visible;

}

.contact-box {
  background: #fff;
  display: flex;
}

.contact-left {
  flex-basis: 60%;
  padding: 40px 60px;
}

.contact-right {
  flex-basis: 40%;
  padding: 40px;
  background: black;
  color: #fff;
  overflow-y: auto; /* Ensure vertical scrollbar when content overflows */

}

h1 {
  margin-bottom: 10px;
}

.container-register p {
  margin-bottom: 40px;
}

.input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.input-row .input-group {
  flex-basis: 45%;

}
input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  padding-bottom: 5px;
}

label {
  margin-bottom: 6px;
  display: block;
  color: black;
}

/* .button-register {
  background: black;
  width: 100px;
  border: none;
  outline: none;
  color: #fff;
  height: 35px;
  border-radius: 30px;
  margin-top: 20px;
  box-shadow: 0px 5px 15px 0px rgba(28, 0, 181, 0.3);
  cursor: pointer;
} */

.button-register {
  width: 120px;
  height: 45px;
  border-radius: 8px;
  border: none;
  background: black;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
}

/* Button hover effect */
.button-register:hover {
  background: #ccc;
  color: black;
}
.contact-left h3 {
  color: black;
  font-weight: 600;
  margin-bottom: 30px;
}

.contact-right h3 {
  font-weight: 600;
  margin-bottom: 30px;
}

tr td  {
  padding-right: 20px;
}

tr td {
  padding-top: 40px;
}


  
/* Your existing styles above */

@media screen and (max-width: 768px) {
  .contact-box {
    flex-direction: column;
  }

  .contact-left,
  .contact-right {
    margin-right: 0;
  }
}